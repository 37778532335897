<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Chatbot</b-card-title>
      <b-avatar
        rounded
        size="42"
        :src="platform.chatbot?platform.chatbot.image:null"
      />
    </b-card-header>

    <b-card-body v-if="platform.chatbot">
      <div
        v-for="message in platform.chatbot.messages"
        :key="message.uuid"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-body>
            <h6 class="transaction-title">
              {{ message.message['fr'] }}
            </h6>
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformViewChatbot',
  components: {
    BCard,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
  },
  computed: {
    ...mapGetters({
      platform: 'platforms/platform',
    }),
  },
}
</script>

<style scoped>

</style>

<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Lots</b-card-title>
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="GiftIcon"
          size="18"
        />
      </b-avatar>
    </b-card-header>

    <b-card-body>
      <div
        v-for="(prize, index) in platform.prizes"
        :key="prize.uuid"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :text="(index + 1).toString()"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ prize.title["fr"] }}
            </h6>
            <small>{{ prize.desc["fr"] }}</small>
          </b-media-body>
        </b-media>
        <b-avatar
          rounded
          size="42"
          :src="prize.image"
          :text="avatarText(prize.title['fr'])"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformViewPrizes',
  setup() {
    return {
      avatarText,
    }
  },
  components: {
    BCard,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  computed: {
    ...mapGetters({
      platform: 'platforms/platform',
    }),
  },
}
</script>

<style scoped></style>

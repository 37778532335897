<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Équipes</b-card-title>
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="UsersIcon"
          size="18"
        />
      </b-avatar>
    </b-card-header>

    <b-card-body>
      <div
        v-for="team in platform.playerTeams"
        :key="team.uuid"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :src="team.image"
              :text="team.name"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ team.name }}
            </h6>
          </b-media-body>
        </b-media>
        <p>
          <feather-icon
            icon="UsersIcon"
            class="mr-75"
          />{{ team.users_count ? team.users_count : 0 }}
        </p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformViewTeams',
  components: {
    BCard,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  computed: {
    ...mapGetters({
      platform: 'platforms/platform',
    }),
  },
}
</script>

<style scoped></style>

<template>
  <div>
    <!-- Platform infos -->
    <b-row>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>

          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="platform.img_logo_after_auth"
                  :text="platform.name"
                  :variant="`light-primary`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1 text-truncate">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ platform.name }}
                    </h4>
                    <b-link
                      class="text-truncate d-block"
                      :href="`https://${platform.subdomain}.ccup.io`"
                      target="_blank"
                    >{{ `${platform.subdomain}.ccup.io` }}</b-link>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="{ name: 'platforms-edit', params: { id: platform.uuid } }"
                      variant="primary"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-b-modal="`modal-delete-platform-${platform.uuid}`"
                      variant="outline-danger"
                      class="ml-0 ml-sm-1"
                      :disabled="deleteLoading"
                    >
                      Supprimer
                    </b-button>
                    <b-modal
                      :id="`modal-delete-platform-${platform.uuid}`"
                      :ref="`modal-delete-platform-${platform.uuid}`"
                      ok-variant="danger"
                      ok-title="Supprimer"
                      modal-class="modal-danger"
                      centered
                      title="Suppression de la plateforme"
                    >
                      <b-card-text>
                        Souhaitez-vous vraiment supprimer cette plateforme ?<br>
                        Ces éléments seront supprimés :<br>
                        -Pronostics (sur les matchs et les questions)<br>
                        -Équipe<br>
                        -Prix<br>
                        -Messages<br>
                        -Chatbot (et ses messages)<br>
                        -Mails autorisés<br>
                        -Invitations<br>
                        -Sponsors<br>
                        -Notifications<br>
                        ✔Les utilisateurs seront encore accessibles
                        <p class="text-danger font-weight-bold">
                          (Cette action est réversible)
                        </p>
                      </b-card-text>
                      <template #modal-footer>
                        <b-button
                          variant="secondary"
                          @click="hideModal()"
                        >
                          annuler
                        </b-button>
                        <b-button
                          variant="danger"
                          :disabled="deleteLoading"
                          @click="deletePlatform()"
                        >
                          <b-spinner
                            v-show="deleteLoading"
                            small
                          />
                          supprimer
                        </b-button>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar
                    variant="light-primary"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ platform.slot * 2 }}€ ht
                    </h5>
                    <small>Prix 2€/places reservées</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ moment(platform.created_at).format("LLL") }}
                    </h5>
                    <small>Date de création</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
              class="text-truncate"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Nom</span>
                  </th>
                  <td class="pb-50">
                    {{ platform.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="Link2Icon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Sous domaine</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ platform.subdomain }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Slot</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ platform.slot }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Messagerie</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ platform.is_messaging_enable ? 'activée': 'désactivée' }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="DribbbleIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Competitions</span>
                  </th>
                  <td
                    v-if="platform.competitions"
                    class="pb-50"
                  >
                    <b-link
                      v-for="competition in platform.competitions"
                      :key="competition.uuid"
                      class="mr-1"
                      :to="{ name: 'competitions-view', params: { id: competition.uuid } }"
                    >{{ `${competition.name}` }}</b-link>
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="Share2Icon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Token invitation</span>
                  </th>
                  <td v-if="platform.invitation">
                    {{ platform.invitation.token }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Languages</span>
                  </th>
                  <td v-if="platform.languages">
                    <b-badge
                      v-for="(language,index) in platform.languages"
                      :key="index"
                      class="mr-1"
                      variant="light-primary"
                    >
                      {{ language.language_code }}
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-horizontal
          v-else
          color="success"
          icon="UsersIcon"
          :statistic="`${platform.users_count}/${platform.slot}`"
          statistic-title="Joueurs"
        />
      </b-col>
      <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-horizontal
          v-else
          color="danger"
          icon="TrendingUpIcon"
          :statistic="`${platform.participation_rate || 0}%`"
          statistic-title="Participation"
        />
      </b-col>
      <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-horizontal
          v-else
          color="warning"
          icon="ZapIcon"
          :statistic="platform.forecasts_count + platform.forecasts_quiz_count"
          statistic-title="Pronostics"
        />
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <b-card-text class="mb-1">
            Taux de bons pronotics
          </b-card-text>
          <b-progress
            variant="warning"
            max="100"
          >
            <b-progress-bar
              :value="rateGoodForecast"
              :label="`${rateGoodForecast}%`"
              variant="warning"
            />
          </b-progress>
        </b-card>
      </b-col>
      <b-col
        xl="3"
        md="4"
        sm="6"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-horizontal
          v-else
          color="info"
          icon="MessageSquareIcon"
          :statistic="platform.messages_count"
          statistic-title="Messages"
        />
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <statistic-card-horizontal
          v-else
          color="info"
          icon="MessageSquareIcon"
          :statistic="platform.messages_today_count"
          statistic-title="Messages aujourd'hui"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <template v-else>
          <PlatformViewPrizes />
          <PlatformViewTeams />
        </template>
      </b-col>
      <b-col>
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <template v-else>
          <PlatformViewChatbot />
          <PlatformViewAllowedMails />
          <PlatformViewSponsors />
        </template>
      </b-col>
    </b-row>
    <h3 class="text-center mb-3 mt-1">
      Utilisateurs
    </h3>
    <b-card no-body>
      <users-list-filters
        :role-filter.sync="userRoleFilter"
        :trashed-filter.sync="userTrashedFilter"
        :role-options="userRoleOptions"
        :trashed-options="userTrashedOptions"
      />
      <users-list
        :api-url="apiUrl"
        :role-filter="userRoleFilter"
        :platform-filter="userPlatformFilter"
        :trashed-filter="userTrashedFilter"
        :table-columns="userTableColumns"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BLink, BRow, BCol, BAvatar, BButton, BSpinner, BProgress, BProgressBar, BCardText, BModal, BBadge,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import PlatformViewPrizes from '@/views/platforms/platforms-view/PlatformViewPrizes'
import PlatformViewChatbot from '@/views/platforms/platforms-view/PlatformViewChatbot'
import PlatformViewTeams from '@/views/platforms/platforms-view/PlatformViewTeams'
import PlatformViewAllowedMails from '@/views/platforms/platforms-view/PlatformViewAllowedMails'
import PlatformViewSponsors from '@/views/platforms/platforms-view/PlatformViewSponsors'
import UsersListFilters from '@/components/lists/users/UsersFilter.vue'
import UsersList from '@/components/lists/users/UsersList.vue'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'

export default {
  name: 'PlatformsView',
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    BButton,
    BSpinner,
    PlatformViewPrizes,
    PlatformViewChatbot,
    PlatformViewTeams,
    PlatformViewAllowedMails,
    PlatformViewSponsors,
    StatisticCardHorizontal,
    BProgress,
    BCardText,
    BModal,
    BProgressBar,
    UsersListFilters,
    UsersList,
    BBadge,
  },
  data() {
    return {
      loading: true,
      deleteLoading: false,
      userPlatformFilter: null,
      userRoleFilter: null,
      userPlatformOptions: [],
      userRoleOptions: [
        { label: 'superadmins', value: 'superAdmin' },
        { label: 'admins', value: 'admin' },
        { label: 'joueurs', value: 'player' },
      ],
      userTrashedFilter: false,
      userTrashedOptions: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
      userTableColumns: [
        {
          key: 'name',
          label: 'Utilisateur',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: false,
        },
        {
          key: 'notification',
          label: 'Notification',
          sortable: false,
        },
        {
          key: 'email_verified',
          label: 'Mail vérifié',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Date de d\'inscription',
          sortable: true,
        },
        { key: 'actions' },
      ],
      apiUrl: 'users',
    }
  },
  computed: {
    ...mapGetters({
      platform: 'platforms/platform',
    }),
    rateGoodForecast() {
      return Math.round((this.goodForecastCount / ((this.goodForecastCount + this.badForecastCount) === 0 ? 1 : (this.goodForecastCount + this.badForecastCount))) * 100)
    },
    goodForecastCount() {
      return (this.platform.good_forecasts_count + this.platform.good_forecasts_quiz_count)
    },
    badForecastCount() {
      return this.platform.bad_forecasts_count + this.platform.bad_forecasts_quiz_count
    },
  },
  created() {
    this.apiUrl = `platforms/${this.$route.params.id}/users`
  },
  mounted() {
    this.fetchPlatform()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchPlatform() {
      try {
        this.loading = true
        await this.$store.dispatch('platforms/fetchPlatform', this.$route.params.id)
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.platform.name,
          breadcrumb: [
            {
              text: 'Plateformes',
              active: false,
              to: {
                name: 'platforms',
              },
            },
            {
              text: this.platform.name,
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deletePlatform() {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`platforms/${this.platform.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La platforme a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal()
        await this.$router.push({
          path: '/platforms',
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    hideModal() {
      this.$refs[`modal-delete-platform-${this.platform.uuid}`].hide()
    },
  },
}
</script>

<style scoped>

</style>

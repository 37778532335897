<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Sponsors</b-card-title>
      <b-avatar
        variant="light-primary"
        rounded
      >
        <feather-icon
          icon="DollarSignIcon"
          size="18"
        />
      </b-avatar>
    </b-card-header>

    <b-card-body>
      <div
        v-for="(sponsor, index) in platform.sponsors"
        :key="sponsor.uuid"
        class="transaction-item"
      >
        <b-media
          no-body
        >
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :src="sponsor.image"
              :text="(index + 1).toString()"
            />
          </b-media-aside>
          <b-media-body>
            <h6>
              {{ sponsor.url }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex">
          <b-badge :variant="'light-success'">
            <feather-icon
              icon="MousePointerIcon"
              size="18"
            />
            {{ sponsor.clicks }}
          </b-badge>
          <b-badge
            class="ml-1"
            :variant="'light-success'"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
            {{ sponsor.views }}
          </b-badge>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  name: 'PlatformViewSponsors',
  setup() {
    return {
      avatarText,
    }
  },
  components: {
    BCard,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BBadge,
  },
  computed: {
    ...mapGetters({
      platform: 'platforms/platform',
    }),
  },
}
</script>

<style scoped>

</style>
